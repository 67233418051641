import { Link } from "gatsby-link";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { TrendingIcon } from "../../../assets/icons";
import "../../../styles/components/TrendingArticles.scss";
import CustomButton from "../../CustomButton";
import BlogCard from "./BlogCard";
import TooltipCard from "./TooltipCard";

const TrendingArticles = ({ trendingArticles, location }) => {
  const [target, setTarget] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const [visibleArticles, setVisibleArticles] = useState();

  useEffect(() => {
    setVisibleArticles(isTablet ? 4 : trendingArticles.length);
  }, []);

  const isShowMore = visibleArticles < trendingArticles.length;

  const handleShowMore = () => {
    setVisibleArticles(isShowMore ? trendingArticles.length : -3);
  };

  return (
    <section className="flex flex-col w-full lg:max-w-[368px] card-3d bg-white px-5 py-7 rounded-[18px] border border-solid border-gray-1400 backdrop-blur-[2px]">
      <section className="flex items-center gap-2">
        <TrendingIcon />
        <h6 className="text-2xl font-bold  tracking-tightest_5 font-manrope text-purple-1100">
          Trending Articles
        </h6>
      </section>

      <section className="mt-6 flex sm:grid lg:flex flex-col justify-between h-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 max-h-[calc(80px*7)]">
        {trendingArticles.slice(0, visibleArticles).map((article, index) => (
          <>
            <Link
              to={`/blog/${article?.node?.uid}/`}
              className="apply border border-transparent border-solid flex gap-[16px] items-center h-full max-h-[75px] px-1.5 py-2.5 w-full list_hover cursor-pointer rounded-md"
              key={article?.node?.ud}
              onMouseEnter={() => {
                setTarget("Index");
                setActiveIndex(index);
              }}
              onMouseLeave={() => setTarget(null)}
            >
              <h6 className="text-2xl font-semibold text-purple-2100">
                {index + 1}
              </h6>
              <h6 className="text-base font-medium tracking-tighter_3 text-gray-2500">
                {article?.node?.data?.title?.text}
              </h6>
            </Link>
          </>
        ))}
      </section>

      <CustomButton
        onClick={handleShowMore}
        text={isShowMore ? "Show more" : "Show less"}
        buttonClass="max-w-[152px] mx-auto block lg:hidden text-base font-manrope text-gray-2500 bg-gray-2800 font-semibold lg:px-4 lg:py-2 rounded-full border border-gray-2200 hover:border-gray-2500"
        isFullWidth
        icon={
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transform: isShowMore ? "rotate(360deg)" : "rotate(180deg)",
            }}
          >
            <path
              d="M9 11.5L13.8125 17L18.625 11.5"
              stroke="#595959"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
      />

      {target && (
        <TooltipCard className={target} offset={{ x: 20, y: 20 }}>
          <BlogCard
            searchText={""}
            location={location}
            blog={trendingArticles[activeIndex]}
            containerClasses="max-w-[368px]"
          />
        </TooltipCard>
      )}
    </section>
  );
};

export default TrendingArticles;
