import React from "react";
import { Link } from "gatsby-link";
import { RocketIcon, TimeIcon } from "../../../assets/icons";
import Tag from "./Tag";
import { formatDateYearShort } from "../../../utilities/FormatBlogDate";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
/**
 * Renders the featured article blogs cards in the grid view
 * @param {featuredBlog, searchText, tag} Object
 * @returns JSX.Element
 */

const FeaturedCard = ({ featuredBlog }) => {
  const { title, content, image } = featuredBlog?.node?.data ?? {};

  if (featuredBlog) {
    return (
      <Link to={`/blog/${featuredBlog?.node?.uid}/`} className="flex-1">
        <section className="flex flex-col h-full bg-white rounded-[18px] border border-solid border-gray-1400 card-3d backdrop-blur-[2.5px] lg:max-h-[706px]">
          <section className="relative">
            <GatsbyImage
              image={getImage(image)}
              alt={image?.alt || "blog banner"}
              className="aspect-[16/9] rounded-t-[18px] object-cover h-full w-full"
              quality={100}
            />

            {/* <h6 className="text-white left-0 top-8 tracking-widest_1 absolute flex items-center font-medium uppercase text-base px-5 py-2.5 bg-purple-1500 gap-1 rounded-tr-md rounded-br-md">
              <RocketIcon />
              <span>Featured</span>
            </h6> */}
          </section>

          <section className="flex flex-col p-6 text-left sm:p-7">
            <section className="mb-3.5 self-start flex flex-row gap-2.5 flex-wrap items-center">
              <div className="flex px-2.5 py-1.5 bg-purple-2100 rounded-full items-center gap-1">
                <RocketIcon />
                <span className="text-white font-semibold tracking-widest_2 font-manrope text-sm uppercase">
                  Featured
                </span>
              </div>
              {featuredBlog?.node?.tags.map((tag, index) => (
                <Tag key={index} text={tag} size="lg" />
              ))}
            </section>

            <h4 className="text-2xl sm:text-2.5xl line-clamp-2 sm:line-clamp-1 font-manrope tracking-tighter_3 font-semibold text-purple-1100">
              {title?.text}
            </h4>

            <p className="pt-2 text-base line-clamp-2 sm:text-lg tracking-tighter_3 text-gray-600 sm:pt-4">
              {content?.text}
            </p>

            <section className="flex flex-row justify-between items-center mt-3.5 text-gray-1200 text-xs sm:text-base tracking-[1.2px] sm:tracking-widest_1 font-medium uppercase">
              <p>
                {formatDateYearShort(
                  featuredBlog?.node?.first_publication_date
                ).replace(/,/g, "")}
              </p>

              <p className="flex flex-row gap-1.5 mt-auto items-center">
                <TimeIcon />
                <span>{featuredBlog?.node?.data?.read_time} m</span>
              </p>
            </section>
          </section>
        </section>
      </Link>
    );
  } else {
    return null;
  }
};

export default FeaturedCard;
